import { UPDATE_MONITORS, RESET_MONITORS } from '../types';

const initState = null;
const monitorsData = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_MONITORS:
      return action.payload;
    case RESET_MONITORS:
      return initState;
    default:
      return state;
  }
};

export default monitorsData;
