import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import 'antd/dist/antd.css';
import 'sass/index.sass';

const Home = lazy(() => import('pages/home'));
const renderLoader = () => <p></p>;

function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <Suspense fallback={renderLoader()}>
          <Switch>
            {/* redirect to default app */}
            <Route path="/" exact component={Home} />
          </Switch>
        </Suspense>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
