import { UPDATE_TEMPORAL_DATA, RESET_TEMPORAL_DATA } from '../types';

const initState = null;
const temporalData = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_TEMPORAL_DATA:
      return action.payload;
    case RESET_TEMPORAL_DATA:
      return initState;
    default:
      return state;
  }
};

export default temporalData;
