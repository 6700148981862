import { combineReducers } from 'redux';
import monitorsData from './monitors';
import temporalData from './temporal-data';
import loadingTemporal from './laoding-temporal';
import loadingSpatial from './loading-spatial';

const rootReducer = combineReducers({
  monitorsData: monitorsData,
  temporalData: temporalData,
  loadingTemporal: loadingTemporal,
  loadingSpatial: loadingSpatial,
});

export default rootReducer;
