import { DISABLE_LOADING_TEMPORAL, ENABLE_LOADING_TEMPORAL } from '../types';

const disable = false;
const loadingTemporal = (state = disable, action) => {
  switch (action.type) {
    case DISABLE_LOADING_TEMPORAL:
      return action.payload;
    case ENABLE_LOADING_TEMPORAL:
      return action.payload;
    default:
      return state;
  }
};

export default loadingTemporal;
